import React, { FC, useState } from 'react';
import { Flex, Grid, Text } from 'theme-ui';
import ImgLoader from '../Generic/ImgLoader';
import ShopAllColorSwatch from './ColorSwatch';
import { TransformedProduct } from '~/types/filter';
import ProductLink from '../Product/Link';
import { useBadge } from '~/hooks/components/use-badge';

type Props = {
  product: TransformedProduct;
};

type Variant = Queries.VariantDetailsFragmentFragment;

const ShopAllProductCard: FC<Props> = ({ product }) => {
  const { shopAllFeatured, badges } = product;
  const [currentlyHoveredVariant, setCurrentlyHoveredVariant] = useState<Variant | null>(null);
  const [currentlyHoveredOptionValue, setCurrentlyHoveredOptionValue] =
    useState<Queries.OptionValuesFragmentFragment | null>(null);
  const [colorQuery, setColorQuery] = useState<string | null>(null);
  const badge = useBadge({ selectedOption: currentlyHoveredOptionValue, badges: badges as Queries.BadgeFragment[] });

  const handleColorSwatchHover = (optionValue: Queries.OptionValuesFragmentFragment) => {
    setCurrentlyHoveredOptionValue(optionValue);
    const variant = product?.variants?.find((v) => v?.optionValues?.find((o) => o?.slug === optionValue?.slug));
    if (variant) setCurrentlyHoveredVariant(variant);
    const search = new URLSearchParams();
    if (optionValue?.presentation) {
      // If the user hovers over a color swatch, update the URL query string
      search.set('color', optionValue.presentation);
      setColorQuery(search.toString());
    }
  };

  const slug = colorQuery ? `${product.slug}?${colorQuery}` : product.slug;

  const isOnSale = (product.variants?.[0]?.regularPrice ?? 0) - product.filterData.price !== 0;

  return (
    <ProductLink
      productSlug={slug}
      collectionSlug={product.collection?.[0]?.slug}
      productName={product.name}
      locationDetail="Shop All"
      sx={{
        maxWidth: ['100%', '500px'],
        gridColumn: shopAllFeatured ? ['span 2', 'auto'] : 'auto',
        isolation: 'isolate',
        position: 'relative',
      }}
    >
      <Grid
        sx={{
          gap: 0,
          mb: ['.8rem', '1.6rem'],
        }}
      >
        <ImgLoader
          image={currentlyHoveredVariant?.mainImage ?? product.additionalContentImage}
          badge={badge}
          forwardSx={{
            mb: '1.6rem',
            aspectRatio: ['1/1'],
          }}
        />

        <Flex sx={{ gap: '.8rem', flexDirection: 'column' }}>
          <Flex sx={{ justifyContent: 'space-between', alignItems: 'bottom', width: '100%' }}>
            <Text
              variant="text.h7"
              sx={{ width: 'fit-content' }}
              dangerouslySetInnerHTML={{ __html: product?.longTitle?.replace('&nbsp;', ' ') ?? '' }}
            />

            <Text
              variant="text.paragraphLarge"
              sx={{
                width: 'fit-content',
                flexShrink: 0,
                justifySelf: [shopAllFeatured ? 'end' : 'start', 'end'],
                whiteSpace: 'nowrap',
                lineHeight: 1,
              }}
            >
              $ {product.filterData.price}
            </Text>
          </Flex>
          <Flex sx={{ justifyContent: 'space-between', alignItems: 'top' }}>
            <div sx={{ width: '80%' }}>
              <ShopAllColorSwatch
                product={product}
                forwardSx={{ gap: '2xs', '--dots': '1.6rem', py: '.25rem' }}
                handleColorSwatchHover={handleColorSwatchHover}
              />
            </div>
            {isOnSale && (
              <Text
                variant="text.paragraphMain"
                sx={{
                  textDecoration: 'line-through',
                  whiteSpace: 'nowrap',
                  color: 'UIGray2',
                  justifySelf: [shopAllFeatured ? 'end' : 'start', 'end'],
                  lineHeight: 1,
                }}
              >
                $ {product.variants?.[0]?.regularPrice}
              </Text>
            )}
          </Flex>
          <Text as="div" variant="text.paragraphMain" sx={{ justifySelf: [shopAllFeatured ? 'end' : 'start', 'end'] }}>
            {product.microloadHighlight?.replace(/microload/i, '')}
          </Text>
        </Flex>
      </Grid>
    </ProductLink>
  );
};

export default ShopAllProductCard;
