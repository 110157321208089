import React, { useEffect, useState } from 'react';
import { useShopFilter } from '../Generic/Filter';
import { Grid } from 'theme-ui';
import { useTheme } from '~/gatsby-plugin-theme-ui';
import { isTransformedProduct } from '~/types/utils';
import ShopAllProductCard from './ProductCard';
import ShopAllBundleCard from './BundleCard';
import { useShopAllProductCardSize } from '~/hooks/components/use-shop-all-product-card-size';
import NoProducts from './NoProducts';
import useMountedKey from '~/hooks/utils/use-mounted-key';

const ShopAllGrid = () => {
  const {
    state: {
      saved: { filteredProducts },
    },
  } = useShopFilter();
  const { theme } = useTheme();

  const key = useMountedKey();

  const updatedFilteredProducts = useShopAllProductCardSize(filteredProducts);

  if (key === 'server') return null;

  return (
    <Grid
      sx={{
        px: [theme.space['3md'], theme.space.lg],
        gridTemplateColumns: ['1fr 1fr', null, '1fr 1fr 1fr'],
        columnGap: ['2sm', '2md'],
        rowGap: ['lg', '3md'],
        maxWidth: ['100%', '1024px', '1520px'],
        mx: 'auto',
        width: '100%',
        pb: 'xxl',
      }}
    >
      {updatedFilteredProducts.length === 0 ? (
        <NoProducts />
      ) : (
        updatedFilteredProducts.map((product) =>
          isTransformedProduct(product) ? (
            <ShopAllProductCard key={product.id} product={product} />
          ) : (
            <ShopAllBundleCard key={product.id} bundle={product} />
          )
        )
      )}
    </Grid>
  );
};

export default ShopAllGrid;
