import { useEffect, useState } from 'react';
import { TransformedBundleOrProductOrKit } from '~/types/filter';
import { isTransformedProduct } from '~/types/utils';

export const useShopAllProductCardSize = (productList: TransformedBundleOrProductOrKit[]) => {
  const [productListWithForcedCardSize, setProductListWithForcedCardSize] = useState(productList);

  useEffect(() => {
    const newProductList = forceCardSizeIfOrphaned(productList);
    setProductListWithForcedCardSize(newProductList);
  }, [productList.length, productList]);

  return productListWithForcedCardSize;
};

const forceCardSizeIfOrphaned = (productList: TransformedBundleOrProductOrKit[]) => {
  let lastLargeCardIndex: number | null = null; // to keep track of the last index that had a large card
  const newFilteredProducts = productList.map((product, index) => {
    const previousProduct = productList[index - 1];
    const nextProduct = productList[index + 1];

    const isBundle = (prod: TransformedBundleOrProductOrKit): boolean =>
      prod?.__typename === 'ContentfulKit' || prod?.__typename === 'ContentfulBundle';

    const isFeatured = (prod: TransformedBundleOrProductOrKit): boolean =>
      isTransformedProduct(prod) && Boolean(prod?.shopAllFeatured);

    const isLargeCard = (prod: TransformedBundleOrProductOrKit): boolean => isBundle(prod) || isFeatured(prod);

    const isOddIndexFromLastLargeCardIndex = (index: number, lastLargeCardIndex: number | null): boolean =>
      (lastLargeCardIndex !== null && (index - lastLargeCardIndex) % 2 !== 0) ||
      (lastLargeCardIndex === null && index % 2 === 0);

    if (isLargeCard(product)) {
      // if the current product is a large card, update the lastLargeCardIndex
      lastLargeCardIndex = index;
    }

    if (index === 0 && isLargeCard(nextProduct)) {
      lastLargeCardIndex = index;
      return { ...product, shopAllFeatured: true };
    }

    if (isLargeCard(previousProduct) && isLargeCard(nextProduct)) {
      lastLargeCardIndex = index;
      return { ...product, shopAllFeatured: true };
    }

    if (
      index === productList.length - 1 &&
      (isLargeCard(previousProduct) || isOddIndexFromLastLargeCardIndex(index, lastLargeCardIndex))
    ) {
      lastLargeCardIndex = index;
      return { ...product, shopAllFeatured: true };
    }

    if (isOddIndexFromLastLargeCardIndex(index, lastLargeCardIndex) && isLargeCard(nextProduct)) {
      lastLargeCardIndex = index;
      return { ...product, shopAllFeatured: true };
    }
    return product;
  });
  return newFilteredProducts;
};
